<template>
  <div>
    <data-iterator :headers="payloadHeaders" :payload="payload" class="elevation-0"></data-iterator>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card elevation="0" tile>
        <v-card-title class="primary darken-2 white--text pa-4 pb-3">
          <span class="body-1 font-weight-medium">
            {{ shiftObj._id ? 'Update Shift' : 'Add Shift' }}
          </span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-form ref="form">
            <v-container fluid class="pa-1">
              <v-row no-gutters class="pa-2 pb-0">
                <v-col cols="12" class="pa-1">
                  <v-text-field outlined dense hide-details label="Name" v-model="shiftObj.name" :rules="setValidation('requiredValidation')"
                  validate-on-blur></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pa-1">
                  <v-menu ref="startMenu" v-model="shiftObj.startMenu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="mb-1" outlined label="from" placeholder="from" v-model="shiftObj.start" prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" hide-details dense></v-text-field>
                    </template>
                    <v-time-picker format="24hr" v-model="shiftObj.start" @click:minute="$refs.startMenu.save(shiftObj.start)" full-width></v-time-picker> 
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="6" class="pa-1">
                  <v-menu ref="endMenu" v-model="shiftObj.endMenu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="mb-1" outlined label="to" placeholder="to" v-model="shiftObj.end" prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" hide-details dense></v-text-field>
                    </template>
                    <v-time-picker format="24hr" v-model="shiftObj.end" @click:minute="$refs.endMenu.save(shiftObj.end)" full-width></v-time-picker> 
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="6" class="pa-1" align-self="center">
                  <v-checkbox outlined dense hide-details label="Is Active" v-model="shiftObj.isactive"></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveUpdateHandler()"> {{ shiftObj._id ? 'Update' : 'Save' }} </v-btn>
          <v-btn @click="dialog = false; $refs.form.reset(); shiftObj = {};" color="error"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      shiftTab: 0,
      listOfUserRoles: [],
      listOfEmployees: [],
      dialog: false,
      shiftObj: {},
      payload: {
        showBackBtn: false,
        create: this.openDialog,
        items: [],
        showSelect: false,
        actionsList: [{
          click: (item) => this.editHandler(item._id),
          icon: 'mdi-pencil',
          color: 'primary'
        }, {
          click: (item) => this.deleteHandler(item._id),
          icon: 'mdi-delete',
          color: 'error'
        }]
      }
    }
  },
  components: {
   'data-iterator': () => import('@/components/DataIterator.vue'),
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Start',
        value: 'start'
      }, {
        text: 'End',
        value: 'end'
      }, {
        text: 'Is active',
        value: 'isactive',
        type: 'BOOLEAN'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  mounted () {
    this.$store.dispatch('getUserRoles').then((data) => {
      this.listOfUserRoles = data.filter((roleItem) => roleItem.code === 2)
    })
    this.$store.dispatch('getUsersList', { only: [ 2 ] }).then((data) => {
      this.listOfEmployees = data
    })
    this.getListHandler()
  },
  methods: {
    getListHandler () {
      this.$_execute('get', 'shifts').then(({ data }) => {
        this.payload = { ...this.payload, items: data }
      })
    },
    openDialog () {
      this.dialog = true
    },
    addOrRemoveBreaks(type, index) {
      if (!this.shiftObj.breaks) this.shiftObj.breaks = [] 
      if (type === 'PUSH') this.shiftObj.breaks.push({})
      else if (type === 'POP') this.shiftObj.breaks.splice(index, 1); 
      this.shiftObj = { ...this.shiftObj }
    },
    saveUpdateHandler () {
      if (this.$refs.form && this.$refs.form.validate()) {
        let url = this.shiftObj._id ? `shifts/${this.shiftObj._id}` : `/shifts`
        let method = this.shiftObj._id ? 'put' : 'post'
        this.$_execute(method, url, this.shiftObj)
          .then(() => {
            this.$root.$emit('snackbar', { message: this.shiftObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
            this.$refs.form.reset()
            this.shiftObj = {}
            this.getListHandler()
          })
          .finally(() => { this.dialog = false })
      } else {
        this.$root.$emit('snackbar', { color: 'error', message: 'Please check the required fields!' })
      }
    },
    editHandler (id) {
      this.$_execute('get', `shifts/${id}`).then(({ data }) => {
        this.shiftObj = data[0]
        this.dialog = true
      })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'shifts',
        _id: id,
        onResolve: () => {
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>

<style>

</style>